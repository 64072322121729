import { type MetaFunction } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useRouteError } from '@remix-run/react';
import { metaV1 } from '@remix-run/v1-meta';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { type ReactElement } from 'react';
import ErrorPage from '~/components/ErrorPage';
import styles from './styles/app.css?url';
import { processRouteError } from '~/utils/processRouteError';

export const ErrorBoundary = (): ReactElement => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  const { errorMessage, errorStatus, statusTitle } = processRouteError(error);

  return (
    <html lang="en">
      <head>
        <title>{statusTitle}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <section className="min-h-screen flex flex-col justify-center items-center">
          <ErrorPage status={errorStatus} statusTitle={statusTitle} statusText={errorMessage} />
        </section>
      </body>
    </html>
  );
};

export function links(): Array<{ rel: string; href: string }> {
  return [
    { rel: 'stylesheet', href: styles },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap',
    },
  ];
}

export const meta: MetaFunction = args =>
  metaV1(args, {
    charset: 'utf-8',
    title: 'CareDash',
    viewport: 'width=device-width,initial-scale=1',
  });

export async function loader(): Promise<any> {
  return {
    ENV: {
      APP_ENV: process.env.APP_ENV,
      VERSION: process.env.npm_package_version,
    },
  };
}

// Never reloading the root since env vars don't change
export const shouldRevalidate = (): boolean => false;

export type LoaderData = SerializeFrom<typeof loader>;

function App(): ReactElement {
  const data = useLoaderData<typeof loader>();
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <title>CareDash</title>
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App, {
  errorBoundaryOptions: {
    onError: () => true,
  },
});
